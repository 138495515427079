$(document).on("turbolinks:load", function () {
  $(".selectize").selectize({
    valueField: "code",
    labelField: "code",
    searchField: ["code", "activity"],
    options: [],
    create: false,
    render: {
      option: (item, escape) => {
        return `
          <div class="ciiu-code-option">
            <span><strong>${escape(item.code)}</strong></span>
            <p>${escape(item.activity)}</p>
          </div>
        `;
      },
    },
    load: function (query, callback) {
      if (!query.length) return callback();

      $.ajax({
        url: `/ciiu_codes?q=${encodeURIComponent(query)}`,
        type: "GET",
        error: () => {
          callback();
        },
        success: (res) => {
          console.log(res);
          callback(res.slice(0, 10));
        },
      });
    },
  });
});
