window.plotCharts = (score_percentage, detailScores, detailLabels, averages) => {
  let generalCtx = document.getElementById('general_chart').getContext('2d');
  let generalScore = score_percentage;
  let generalMaxScore = 100 -  score_percentage;

  let generalChartPromise = new Promise((resolve, reject) => {
    new Chart(generalCtx, {
      type: 'doughnut',
      data: {
        datasets: [{
          fill: true,
          data: [generalScore, generalMaxScore],
          backgroundColor: ['#37a2eb', '#ff6384']
        }],
        labels: ['% Puntaje', '% Mejora'],
        borderColor: "#fff"
      },
      options: {
        title: {
          display: true,
          text: 'Diagnostico General',
          position: 'top'
        },
        animation: {
          duration: 1000,
          onComplete: function () {
            resolve(true);
          }
        }
      }
    });
  })

  // Chart Details
  let detailsCtx = document.getElementById('details_chart').getContext('2d');

  let datasets = [{
    label: 'Puntaje',
    fill: true,
    data: detailScores,
    backgroundColor: 'rgba(255,99,132,0.2)',
    borderColor: "rgba(255,99,132,1)",
    pointBorderColor: "#fff",
    pointBackgroundColor: "rgba(255,99,132,1)",
    pointBorderColor: "#fff",
  }];

  if (averages) {
    let averageScores = detailLabels.map(label => averages[label]);
    datasets = [...datasets, {
      label: 'Promedio del sector',
      fill: true,
      data: averageScores,
      backgroundColor: 'rgba(214, 236, 251, 0.2)',
      borderColor: "rgba(64, 162, 235, 1)",
      pointBorderColor: "#fff",
      pointBackgroundColor: "rgba(64, 162, 235, 1)",
      pointBorderColor: "#fff",
    }]
  }

  let detailChartPromise = new Promise((resolve, reject) => {
    new Chart(detailsCtx, {
      type: 'radar',
      data: {
        labels: detailLabels,
        datasets,
        borderColor: "#fff"
      },
      options: {
        title: {
          display: true,
          text: 'Fortalezas y Debilidades',
          position: 'top'
        },
        animation: {
          duration: 1000,
          onComplete: function () {
            resolve(true);
          }
        },
        scale: {
          angleLines: {
            display: true
          },
          ticks: {
            suggestedMin: 10,
            suggestedMax: 100
          }
        }
      }
    });
  });

  return Promise.all([generalChartPromise, detailChartPromise]);
}